var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c(
        "div",
        [
          _vm._l(_vm.value, function (message, i) {
            return [
              _c("v-icon", { key: i, attrs: { left: "", small: "" } }, [
                _vm._v("mdi-close"),
              ]),
              _c("span", { key: i, staticClass: "v-messages error--text" }, [
                _vm._v(_vm._s(_vm.capitalize(message))),
                _c("br"),
              ]),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }