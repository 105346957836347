var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        "offset-y": "",
        "close-on-content-click": false,
        "max-height": "80%",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var ton = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      icon: !_vm.$vuetify.breakpoint.mdAndUp,
                                      "aria-label":
                                        _vm.ae$lng("page.columnFilter"),
                                      text: _vm.$vuetify.breakpoint.mdAndUp,
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                Object.assign({}, on, ton)
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color:
                                        _vm.appConfig.window.formActions.color,
                                      left: _vm.$vuetify.breakpoint.mdAndUp,
                                    },
                                  },
                                  [_vm._v(" $options ")]
                                ),
                                _vm.$vuetify.breakpoint.mdAndUp
                                  ? [
                                      _c(
                                        "span",
                                        { staticClass: "text-capitalize" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.ae$lng("page.columnFilter")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.ae$lng("page.columnFilterTooltip"))),
                  ]),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "", flat: "" } },
        [
          _c(
            "v-list-item-group",
            {
              attrs: { multiple: "" },
              model: {
                value: _vm.value2,
                callback: function ($$v) {
                  _vm.value2 = $$v
                },
                expression: "value2",
              },
            },
            _vm._l(_vm.displayHeaders, function (item) {
              return _c("v-list-item", {
                key: item.value,
                attrs: { value: item.value },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var active = ref.active
                        return [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-checkbox", {
                                attrs: { "input-value": active },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.text)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }