var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.viewMode
        ? _c("asyent-form-display", {
            attrs: {
              value: _vm.value2,
              "no-label": _vm.noLabel,
              label: _vm.label,
            },
          })
        : _vm._e(),
      !_vm.noLabel && !_vm.viewMode
        ? _c("div", [
            _c(
              "span",
              {
                class: { "field-mandatory": _vm.mandatory },
                staticStyle: { "font-size": "0.85rem" },
              },
              [_vm._v(_vm._s(_vm.label))]
            ),
          ])
        : _vm._e(),
      _c("ValidationProvider", {
        attrs: { name: _vm.label, rules: _vm.formFieldRules, vid: _vm.name },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                !_vm.viewMode
                  ? _c(
                      "v-textarea",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "mt-2",
                            attrs: {
                              "aria-label": _vm.label,
                              disabled: _vm.formFieldDisabled,
                              "error-messages": _vm.formFieldErrors(errors),
                              rows: _vm.rows,
                              clearable: "",
                              counter: !_vm.noCounter,
                              label: "",
                              dense: _vm.appConfig.forms.dense,
                              flat: _vm.appConfig.forms.flat,
                              solo: _vm.appConfig.forms.solo,
                              "solo-inverted": _vm.appConfig.forms.soloInverted,
                              filled: _vm.appConfig.forms.filled,
                              outlined: _vm.appConfig.forms.outlined,
                              "full-width": "",
                            },
                            model: {
                              value: _vm.value2,
                              callback: function ($$v) {
                                _vm.value2 = $$v
                              },
                              expression: "value2",
                            },
                          },
                          "v-textarea",
                          _vm.$attrs,
                          false
                        ),
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }