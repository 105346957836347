var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value &&
    _vm.value["documentStatus"] &&
    _vm.value["documentStatus"].length > 0
    ? _c(
        "asyent-card",
        _vm._b({}, "asyent-card", _vm.$attrs, false),
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "3" } },
                [
                  _c("asyent-form-display", {
                    attrs: {
                      value: [
                        _vm.ae$EnumToString(
                          _vm.value["documentPreviousStatus"]
                        ),
                        _vm.ae$EnumToString(_vm.value["documentStatus"]),
                      ],
                      label: _vm.ae$lng("forms.documentInfo.status"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "3" } },
                [
                  _c("asyent-form-display", {
                    attrs: {
                      value: _vm.value["documentCreatedAt"],
                      "as-date-time": "",
                      label: _vm.ae$lng("forms.documentInfo.createdAt"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "3" } },
                [
                  _c("asyent-form-display", {
                    attrs: {
                      value: _vm.value["documentUpdatedAt"],
                      "as-date-time": "",
                      label: _vm.ae$lng("forms.documentInfo.updatedAt"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "3" } },
                [
                  _c("asyent-form-display", {
                    attrs: {
                      value: _vm.noUser ? "N/A" : _vm.value["documentUserName"],
                      label: _vm.ae$lng("forms.documentInfo.createdUpdatedBy"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }