var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.viewMode && !Array.isArray(_vm.value)
        ? _c("asyent-form-display", {
            attrs: {
              value:
                _vm.value && typeof _vm.value === "object"
                  ? _vm.extractDisplayValues(_vm.value)
                  : _vm.value,
              "no-label": _vm.noLabel,
              label: _vm.label,
            },
          })
        : _vm._e(),
      _vm.viewMode && Array.isArray(_vm.value)
        ? _c("asyent-form-display", {
            attrs: {
              value: _vm.value
                ? _vm.extractDisplayValuesArray(_vm.value)
                : _vm.value,
              "no-label": _vm.noLabel,
              delimiter: ", ",
              label: _vm.label,
            },
          })
        : _vm._e(),
      !_vm.noLabel && !_vm.viewMode
        ? _c("div", [
            _c(
              "span",
              {
                class: { "field-mandatory": _vm.mandatory },
                staticStyle: { "font-size": "0.85rem" },
              },
              [_vm._v(_vm._s(_vm.label))]
            ),
          ])
        : _vm._e(),
      _vm.helpText && !_vm.viewMode
        ? _c("p", { domProps: { textContent: _vm._s(_vm.helpText) } })
        : _vm._e(),
      !_vm.viewMode
        ? _c("ValidationProvider", {
            attrs: { name: _vm.label, rules: _vm.formFieldRules },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var errors = ref.errors
                    return [
                      _c(
                        "v-autocomplete",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mt-2",
                              attrs: {
                                "aria-label": _vm.label,
                                disabled: _vm.isDisabled,
                                "error-messages": _vm.formFieldErrors(errors),
                                "item-text": _vm.itemText,
                                "item-value": _vm.itemValue,
                                items: _vm.itemsArray,
                                loading: _vm.dataLoading,
                                "small-chips": _vm.multiple,
                                filter: _vm.enhancedFilter,
                                clearable: _vm.clearable,
                                "open-on-clear": "",
                                "deletable-chips": "",
                                placeholder: !_vm.noPlaceholder
                                  ? _vm.ae$lng("forms.select.pleaseSelect")
                                  : "",
                                label: "",
                                dense: _vm.appConfig.forms.dense,
                                flat: _vm.appConfig.forms.flat,
                                solo: _vm.appConfig.forms.solo,
                                "solo-inverted":
                                  _vm.appConfig.forms.soloInverted,
                                filled: _vm.appConfig.forms.filled,
                                outlined: _vm.appConfig.forms.outlined,
                                "cache-items": "",
                              },
                              on: {
                                keydown: function ($event) {
                                  if (!$event.shiftKey) {
                                    return null
                                  }
                                  return function () {
                                    return (_vm.clearable = true)
                                  }.apply(null, arguments)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  !_vm.noReload &&
                                  _vm.itemsReload &&
                                  !_vm.isDisabled
                                    ? {
                                        key: "prepend-inner",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              {
                                                on: {
                                                  click:
                                                    _vm.reloadItemsFromParent,
                                                },
                                              },
                                              [_vm._v("mdi-reload")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      }
                                    : null,
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        !_vm.replaceItemData &&
                                        _vm.showItemAvatar
                                          ? _c("v-list-item-avatar", {
                                              staticStyle: {
                                                "justify-content":
                                                  "center !important",
                                              },
                                              attrs: { color: "secondary" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  data.item[_vm.itemValue]
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        !_vm.replaceItemData
                                          ? _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      data.item[_vm.itemText]
                                                    ),
                                                  },
                                                }),
                                                _vm.showItemCode
                                                  ? _c("v-list-item-subtitle", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          data.item[
                                                            _vm.itemValue
                                                          ]
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                  _vm._l(_vm.$slots, function (index, name1) {
                                    return {
                                      key: name1,
                                      fn: function () {
                                        return [_vm._t(name1)]
                                      },
                                      proxy: true,
                                    }
                                  }),
                                  _vm._l(
                                    _vm.$scopedSlots,
                                    function (index, name2) {
                                      return {
                                        key: name2,
                                        fn: function (data) {
                                          return [
                                            _vm._t(name2, null, null, data),
                                          ]
                                        },
                                      }
                                    }
                                  ),
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.selectedArray,
                                callback: function ($$v) {
                                  _vm.selectedArray = $$v
                                },
                                expression: "selectedArray",
                              },
                            },
                            "v-autocomplete",
                            _vm.$attrs,
                            false
                          ),
                          _vm.$listeners
                        )
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1921926599
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }