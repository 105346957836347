var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: _vm.reference + "observer",
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var errorsClient = ref.errors
            return [
              _c(
                "v-form",
                {
                  ref: _vm.reference,
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                  model: {
                    value: _vm.formValid,
                    callback: function ($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid",
                  },
                },
                [
                  _vm.isFormSubmitting
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _vm._t(
                                "formSubmitting",
                                function () {
                                  return [
                                    _c(
                                      "v-alert",
                                      {
                                        attrs: {
                                          type: "success",
                                          transition: "fade",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "text-body-2",
                                            attrs: { dense: "" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.ae$lng(
                                                    "forms.processingMessage"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                { formSuccess: _vm.success }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.success && _vm.success.length > 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _vm._t(
                                "formSuccess",
                                function () {
                                  return [
                                    _c(
                                      "v-alert",
                                      {
                                        attrs: {
                                          type: "success",
                                          transition: "fade",
                                        },
                                      },
                                      _vm._l(
                                        _vm.success,
                                        function (message, i) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: i,
                                              staticClass: "text-body-2",
                                              attrs: { dense: "" },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(message) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                                { formSuccess: _vm.success }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formCombinedErrors(errorsClient) &&
                  _vm.formCombinedErrors(errorsClient).length > 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _vm._t(
                                "formErrors",
                                function () {
                                  return [
                                    _c(
                                      "v-alert",
                                      {
                                        attrs: {
                                          type: "error",
                                          transition: "fade",
                                        },
                                      },
                                      _vm._l(
                                        _vm.formCombinedErrors(errorsClient),
                                        function (message, i) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: i,
                                              staticClass: "text-body-2",
                                              attrs: { dense: "" },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(message) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                                { formErrors: errorsClient }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataLoading || _vm.dataError
                    ? [
                        _vm._t("formDataLoading", function () {
                          return [
                            _c(
                              "v-sheet",
                              {
                                attrs: {
                                  color:
                                    "grey " +
                                    (_vm.$vuetify.theme.dark
                                      ? "darken-2"
                                      : "lighten-4"),
                                },
                              },
                              [
                                _c("v-skeleton-loader", {
                                  staticClass: "mx-auto",
                                  attrs: {
                                    elevation: "0",
                                    type: "card-avatar",
                                  },
                                }),
                                _c("v-skeleton-loader", {
                                  staticClass: "mx-auto",
                                  attrs: { elevation: "0", type: "actions" },
                                }),
                                _c(
                                  "v-row",
                                  { attrs: { justify: "center" } },
                                  [
                                    _vm.dataError
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "text-center text-truncate text-no-wrap",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.ae$lng(
                                                  "forms.unableToLoadMessage"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "text-center text-truncate text-no-wrap",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.ae$lng(
                                                  "forms.loadingWaitMessage"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                      ]
                    : [
                        _vm._t("default", null, {
                          clearable: _vm.editable,
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          readonly: _vm.readonly,
                          submitted: _vm.isFormSubmitted,
                          valid: _vm.formValid,
                        }),
                        _vm.appConfig.window.formActions.bottomActions ||
                        _vm.bottomActions
                          ? _vm._t(
                              "formActionsList",
                              function () {
                                return [
                                  !_vm.dataLoading &&
                                  !_vm.dataError &&
                                  _vm.hasActions
                                    ? [
                                        _c(
                                          "v-list",
                                          {
                                            attrs: {
                                              color: "transparent",
                                              flat: "",
                                              subheader: "",
                                            },
                                          },
                                          [
                                            _vm._t(
                                              "formActionsHeader",
                                              function () {
                                                return [
                                                  _c("v-subheader", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.ae$lng(
                                                          "page.formActions"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              }
                                            ),
                                            _vm._l(
                                              _vm.formActionsFiltered,
                                              function (action) {
                                                return _c("asyent-button", {
                                                  key: action["name"],
                                                  attrs: {
                                                    "button-icon":
                                                      action["icon"],
                                                    "button-tooltip":
                                                      action["tooltip"],
                                                    "requires-confirmation":
                                                      action["action"],
                                                    "as-list-item": "",
                                                    "dense-list": "",
                                                    "as-avatar": "",
                                                    text: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    confirmed: function (
                                                      $event
                                                    ) {
                                                      return _vm.formActionMethod(
                                                        action
                                                      )
                                                    },
                                                  },
                                                })
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                              { formActionsFiltered: _vm.formActionsFiltered }
                            )
                          : _vm._e(),
                      ],
                  _c(
                    "v-bottom-sheet",
                    {
                      attrs: {
                        scrollable: "",
                        "max-width": _vm.$vuetify.breakpoint.mdAndDown
                          ? "100vw"
                          : "50vw",
                      },
                      model: {
                        value: _vm.successDialog,
                        callback: function ($$v) {
                          _vm.successDialog = $$v
                        },
                        expression: "successDialog",
                      },
                    },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass: "text-center",
                          attrs: { color: "secondary" },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "mx-auto pa-2 pa-lg-6",
                              attrs: { color: "secondary", flat: "" },
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { large: "", left: "" } },
                                    [_vm._v(" mdi-check ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-h6 font-weight-regular",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.ae$lng(
                                            "forms.documentVerificationTitle"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "text-h6 text-justify font-weight-regular mx-auto",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ae$lng(
                                          "forms.documentVerificationMessage"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-bottom-sheet",
                    {
                      attrs: {
                        scrollable: "",
                        dark: "",
                        "max-width": _vm.$vuetify.breakpoint.mdAndDown
                          ? "100vw"
                          : "50vw",
                      },
                      model: {
                        value: _vm.errorDialog,
                        callback: function ($$v) {
                          _vm.errorDialog = $$v
                        },
                        expression: "errorDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-auto pa-2 pa-lg-6",
                          attrs: {
                            color: "error",
                            "max-height": _vm.$vuetify.breakpoint.lgAndUp
                              ? 600
                              : 250,
                            flat: "",
                          },
                        },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c("v-icon", { attrs: { large: "", left: "" } }, [
                                _vm._v(" mdi-close "),
                              ]),
                              _c(
                                "span",
                                { staticClass: "text-h6 font-weight-regular" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ae$lng(
                                        "forms.verificationsErrorsTitle"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "text-justify font-weight-regular mx-auto",
                            },
                            [
                              _vm.formCombinedErrors(errorsClient) &&
                              _vm.formCombinedErrors(errorsClient).length > 0
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            {
                                              attrs: {
                                                color: "transparent",
                                                dense: "",
                                              },
                                            },
                                            _vm._l(
                                              _vm.formCombinedErrors(
                                                errorsClient
                                              ),
                                              function (message, i) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: i,
                                                    attrs: { dense: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(i + 1) +
                                                        ". " +
                                                        _vm._s(message) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }